<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Produto' : 'Cadastrar Produto'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="productsRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="3">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome do Produto"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome do produto"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.active"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Edição">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.is_edition"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Tipo *">
              <validation-provider
                #default="{ errors }"
                name="Tipo de Checklist"
                rules="required"
              >
                <v-select
                  v-if="types.length > 0"
                  v-model="typeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  autocomplete="off"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _productsService from "@/services/audiovisual/products-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  data() {
    return {
      btedit: { permission: `permission.audiovisual.demands.products.edit` },
      btcreate: {
        permission: `permission.audiovisual.demands.products.create`,
      },
      btdelete: {
        permission: `permission.audiovisual.demands.products.delete`,
      },
      types: [],
      typeSelected: null,
      loading: false,
      record: {
        id: 0,
        name: "",
        active: true,
        is_edition: true,
        type_media: 0,
      },
    };
  },
  mounted() {},
  created() {
    localize("pt_BR", pt_br);
    this.getTypes();
  },
  methods: {
    validationForm() {
      this.$refs.productsRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      console.log("getRecord");
      if (this.$route.params.id > 0) {
        this.loading = true;
        _productsService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.typeSelected = {
              label: this.returnType(res.content.type_media),
              value: res.content.type_media,
            };

            console.log("SELECIONADO => ", this.typeSelected);
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    returnType(type) {
      return type === 0 ? "Vídeo" : type === 1 ? "Audio" : "PDF";
    },
    getTypes() {
      console.log("getTypes");
      _productsService
        .mediaTypes()
        .then((res) => {
          this.types = this.$utils.populardropdown(
            res,
            "name",
            "value",
            false,
            false
          );
          this.getRecord();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    save() {
      this.record.type_media = this.$utils.getValueSelected(this.typeSelected);

      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _productsService.create(payload)
          : _productsService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>